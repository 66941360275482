import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import style from './index.module.scss'


const IndexPage = () => {

  return (
    <Layout>
      <SEO title='Tony Dupé' />
      <div className={style.pageStyle}>
        <h1>Tony Dupé</h1>
        <p>
          is interested in sound,<br />
          song, instruments,<br />
          people, stories,<br />
          performance, space and<br />
          character<br />
        </p>

        <div className={style.linkWrapper + ' small-text'}>
          <a href='https://tonydupe.bandcamp.com/releases'>Bandcamp</a>
          <a href='https://vimeo.com/user45370418'>Vimeo</a>
          <a href='https://open.spotify.com/artist/11v9Gs74xVa4JUkvFgNNex'>Spotify</a>
          <a href='https://www.facebook.com/Tony-Dup%C3%A9-252705324760950/'>Facebook</a>
          <a href='https://www.instagram.com/tony_dupe/'>Instagram</a>
          <a href='mailto:tonydupecontact@gmail.com?subject=Hello'>Email</a>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
